import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, IconButton, Popover, PopoverProps, Tooltip, Typography } from '@mui/material';
import SetListSongSelect from '../../../set-list-song-select/SetListSongSelect';
import {
  SetListClient,
  SetListViewModel,
  SongTransposeInSetListViewModel,
  SongViewModel,
} from '../../../../../types/auto/types';
import { LoadingButton } from '@mui/lab';
import { Configuration } from '../../../../Constants';
import { FetchOverride } from '../../../../utils/Requests';
import { SetSnackbarErrorContext } from '../../snackbar/SnackbarContext';
import { UserContext } from '../../../../utils/UserStorage';
import { colours, zIndex } from '../../../../Theme';
import DownloadIcon from '@mui/icons-material/Download';
import ReplyIcon from '@mui/icons-material/Reply';
import ShareModal from '../../../set-list-card/ShareModal';
import DownloadPdfModal from './DownloadPdfModal';
import { isMobile } from 'react-device-detect';
import { Edit } from '@mui/icons-material';

interface Props extends PopoverProps {
  setList: SetListViewModel;
  setSetList: React.Dispatch<React.SetStateAction<SetListViewModel | undefined>>;
  setRerender: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAnnotations: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTranspositions: React.Dispatch<React.SetStateAction<boolean>>;
  setSongToView: (song: SongViewModel) => void;
  totalPageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;

  refreshPdf: () => void;
}

const SetListPopover = ({
  setList,
  setSetList,
  setRerender,
  setShowAnnotations,
  setShowTranspositions,
  setSongToView,
  totalPageNumber,
  setPageNumber,
  ...props
}: Props): JSX.Element => {
  const [selectedSongs, setSelectedSongs] = useState<SongViewModel[]>([]);
  const [songError, setSongError] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const setSnackbarError = useContext(SetSnackbarErrorContext);
  const user = useContext(UserContext);
  const userIsOwner = setList.userId === user.id;
  const [allowEditingOnMobile, setAllowEditingOnMobile] = useState(false);

  // Set the default values
  useEffect(() => {
    const songModels = setList.songModels || [];
    setSelectedSongs(songModels);
    setSongError(false);
  }, [setSelectedSongs]);

  return (
    <>
      <DownloadPdfModal
        open={downloadModalOpen}
        setOpen={setDownloadModalOpen}
        setList={setList}
        totalPageNumber={totalPageNumber}
        setPageNumber={setPageNumber}
        setShowAnnotations={setShowAnnotations}
        setShowTranspositions={setShowTranspositions}
      />
      <ShareModal setList={setList} open={shareModalOpen} setOpen={setShareModalOpen} setSetList={setSetList} />
      <Popover {...props} style={{ zIndex: zIndex.firstModal }}>
        <Box style={{ padding: '20px', zIndex: zIndex.firstModal, position: 'relative' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'center',
              alignItems: 'center',
            }}>
            <Typography variant='h6' sx={{ mb: '20px', fontSize: '15px' }}>
              {userIsOwner && !(isMobile && !allowEditingOnMobile) ? 'Update ' : ''}Set List Songs
            </Typography>
            <div>
              {userIsOwner && isMobile && (
                <Tooltip title='Edit Set List' PopperProps={{ style: { zIndex: zIndex.tooltip } }}>
                  <IconButton aria-label='Edit Set List' onClick={() => setAllowEditingOnMobile(x => !x)}>
                    <Edit style={{ transform: 'scaleX(-1)' }} />
                  </IconButton>
                </Tooltip>
              )}
              {userIsOwner && (
                <Tooltip title='Share Set List' PopperProps={{ style: { zIndex: zIndex.tooltip } }}>
                  <IconButton aria-label='Share Set List' onClick={() => setShareModalOpen(true)}>
                    <ReplyIcon style={{ transform: 'scaleX(-1)' }} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                title='Download Set List'
                PopperProps={{ style: { zIndex: zIndex.tooltip } }}
                style={{ paddingTop: '15px' }}>
                <IconButton aria-label='Download Set List' onClick={() => setDownloadModalOpen(true)}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div
            style={{
              marginTop: 15,
              overflow: 'auto',
              width: window.innerWidth > 500 ? '500px' : window.innerWidth - 75,
            }}>
            <SetListSongSelect
              selectedSongs={selectedSongs}
              setSelectedSongs={setSelectedSongs}
              songError={songError}
              setSongError={setSongError}
              hideTitle
              isSetListView
              jumpToCard={song => setSongToView(song)}
              viewOnly={!userIsOwner || (!allowEditingOnMobile && isMobile)}
              onUpdate={() => {
                setHasUnsavedChanges(true);
              }}
              maxHeight='400px'
            />
          </div>
          {userIsOwner && (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                marginTop: '10px',
              }}>
              {hasUnsavedChanges && (
                <Typography
                  style={{ color: colours.textRed, fontSize: '10px', marginRight: '50px', paddingTop: '10px' }}>
                  You Have Unsaved Changes
                </Typography>
              )}
              <Button
                disabled={loading}
                onClick={() => {
                  setSelectedSongs(setList.songModels || []);
                  setSongError(false);
                  setHasUnsavedChanges(false);
                  props.onClose && props.onClose({}, 'escapeKeyDown');
                }}>
                Cancel
              </Button>
              <LoadingButton
                disabled={!hasUnsavedChanges}
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  const newSetList = setList;
                  newSetList.songModels = selectedSongs;
                  new SetListClient(Configuration.SERVER_ROOT, FetchOverride)
                    .updateSetList(
                      newSetList,
                      newSetList.songModels.map(
                        x =>
                          ({
                            songId: x.id,
                            transposeAmount: x.storedTransposition || 0,
                          } as SongTransposeInSetListViewModel)
                      )
                    )
                    .then(() => {
                      setLoading(false);
                      setSetList(newSetList);
                      setHasUnsavedChanges(false);
                      setRerender(x => !x);
                      props.onClose && props.onClose({}, 'escapeKeyDown');
                      props.refreshPdf();
                    })
                    .catch(() => {
                      setSnackbarError('Could not update setlist. Please try again later');
                      setLoading(false);
                    });
                }}>
                Update
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default SetListPopover;
